import React from "react";
import colorGradient from '../../../assets/colorGradient.png'
import logoKbi from '../../../assets/logoKbi.png'
import people from '../../../assets/people.png'
import logoIpc from '../../../assets/logoIpc.png'
import logoHEC from '../../../assets/logoHEC.png'
import logoGendarmerie from '../../../assets/logoGendarmerie.png'
import logoINSP from '../../../assets/logoINSP.png'
import inocap from '../../../assets/inocap.png'
import inGroupe from '../../../assets/inGroupe.png'
import nexodot from '../../../assets/nexodot.png'
import eaglesPhi from '../../../assets/eaglesPhi.png'
export default function Landing() {

    function handleButtonClick() {
        const element = document.getElementById('elementTarget');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return(
        <div className={'landingHomePage'}>
            <img src={colorGradient} alt="" className={'colorGradient'} style={{zIndex: 1}}/>

            <div className={'containerLandingHomePage'} style={{zIndex: 2}}>
                <img src={logoKbi} alt="" className={'logoKbi'}/>
                <h1 className={'kaizenBudoEurope'}>KAIZEN BUDO</h1>
                <h2 className={'international'}>INTERNATIONAL</h2>

                <p className={'mainTitle'}>
                    La première

                    <span className={'degrade'}> formation </span>

                    qui s’intéresse à ce qui se passe à l’intérieur de vos

                    <span className={'degrade'}> collaborateurs</span>
                    .

                </p>

                <p className={'subtitle'}>
                    Nous croyons que l’excellence et le progrès naissent de l’intérieur, et c’est pourquoi notre formation se focalise sur l’éveil du potentiel intérieur de vos collaborateurs et l’amélioration de soi pour atteindre l’excellence professionnelle. Découvrez notre approche dès maintenant.                </p>

                <div className="buttonMore" onClick={handleButtonClick}>
                        EN SAVOIR PLUS
                </div>

                <div className={'joinClients'}>
                    <img src={people} alt="" className={'people'}/>
                    <p className={'joinPro'}>Rejoignez <span style={{color: '#FFF'}}>des milliers</span> de professionnels</p>
                </div>

                <InfiniteScrollLogos/>

            </div>



        </div>
    )
}

const InfiniteScrollLogos = React.memo(() => {
    const logos = [
        { src: logoIpc, alt: "" },
        { src: logoHEC, alt: "" },
        { src: logoGendarmerie, alt: "" },
        { src: logoINSP, alt: "" },
        { src: inocap, alt: "" },
        { src: inGroupe, alt: "" },
        { src: nexodot, alt: "" },
        { src: eaglesPhi, alt: "" },
        { src: logoIpc, alt: "" },
        { src: logoHEC, alt: "" },
        { src: logoGendarmerie, alt: "" },
        { src: logoINSP, alt: "" },
        { src: inocap, alt: "" },
        { src: inGroupe, alt: "" },
        { src: nexodot, alt: "" },
        { src: eaglesPhi, alt: "" },
    ];

    return (
        <div className="infosPart">
            <div className="logosContainer">
                {logos.map((logo, index) => (
                    <img key={index} src={logo.src} alt={logo.alt} className="logo" />
                ))}
                {logos.map((logo, index) => (
                    <img key={index + logos.length} src={logo.src} alt={logo.alt} className="logo" />
                ))}
            </div>
        </div>
    );
})
