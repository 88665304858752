import React from "react";
import logo512 from '../../../assets/logo512.png'

export default function Part1Formation({width}) {



    return(
        <div className={'part1Formation'} style={{overflow: 'hidden', marginTop: width < 940 && 40}}>

            <div className={'containerPart'} style={{zIndex: 2}}>

                {width > 940 &&
                    <div className={'intervenantPart'}>

                        <div style={{display: 'flex', gap: width < 900 ? 15 : 30, marginTop: 20, marginBottom: 20}}>

                            <div style={{display: "flex", gap: width < 900 ? 15 : 30, flexDirection: 'column'}}>
                                <WidgetFormation name={'NIDAN'} where={'Présentiel - En ligne'} cible={'Pour tous'} duree={'6 heures'} width={width}/>
                                <WidgetFormation name={'BLACK BELT'} where={'Présentiel'} cible={'Cadres supérieur'} duree={'24 heures'} width={width}/>

                            </div>

                            <div style={{display: "flex", gap: width < 900 ? 15 : 30, flexDirection: 'column'}}>
                                <WidgetFormation name={'SANDAN'} where={'Présentiel - En ligne'} cible={'Cadres'} duree={'18 heures'} width={width}/>
                                <WidgetFormation name={'FORCES DE L\'ORDRE'} where={'Présentiel'} cible={'Forces de l\'ordre'} duree={'6 heures'} width={width}/>
                            </div>

                        </div>

                    </div>
                }

                <div className={'textPart'}>
                    <p className={'step'}>FORMATION & SÉMINAIRE</p>
                    <p className={'titleStep'}>Une offre de séminaires adaptée à vos besoins</p>
                    <p className={'corpsStep'}>Avec leur expertise reconnue, nos intervenants internationaux dévoileront des stratégies uniques pour illuminer votre potentiel et révéler l’excellence cachée en vous.</p>

                    {width < 940 &&
                        <div style={{alignItems: 'center', justifyContent: 'center', paddingLeft: 8}}>

                            <div style={{display: 'flex', gap: width < 900 ? 15 : 30, marginTop: 30, marginBottom: 30}}>

                                <div style={{display: "flex", gap: width < 900 ? 15 : 30, flexDirection: 'column'}}>
                                    <WidgetFormation name={'NIDAN'} where={'Présentiel - En ligne'} cible={'Pour tous'} duree={'6 heures'} width={width}/>
                                    <WidgetFormation name={'BLACK BELT'} where={'Présentiel'} cible={'Cadres supérieurs'} duree={'24 heures'} width={width}/>
                                </div>

                                <div style={{display: "flex", gap: width < 900 ? 15 : 30, flexDirection: 'column'}}>
                                    <WidgetFormation name={'SANDAN'} where={'Présentiel - En ligne'} cible={'Cadres'} duree={'18 heures'} width={width}/>
                                    <WidgetFormation name={'FORCES DE L\'ORDRE'} where={'Présentiel'} cible={'Forces de l\'ordre'} duree={'6 heures'} width={width}/>
                                </div>

                            </div>

                        </div>
                    }

                    <a href="mailto:jbbevillard@kbi-europe.fr?" className="btn">
                        <div className={'button'}>
                            Demander plus d'informations
                        </div>
                    </a>



                </div>

             </div>
        </div>
    )
}


function WidgetFormation({name, cible, duree, where, width}) {

    return(
        <div className={'widgetFormation'}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={logo512} alt="" className={'logo512'}/>
                <p className={'name degrade'}>{name}</p>
            </div>

            <div style={{display: 'flex', marginTop: 10, flexDirection: width < 900 ? 'column' : 'row'}}>

                <div style={{display: 'flex'}}>
                    <div className={'cible'}>
                        {cible}
                    </div>
                </div>

                <div style={{display: 'flex', marginTop: width < 900 && 10}}>
                    <div className={'duree'}>
                        {duree}
                    </div>
                </div>

            </div>

            <div style={{display: 'flex'}}>
                <div className={'where'}>
                    {where}
                </div>
            </div>



        </div>
    )
}
