import React, {useEffect, useState} from "react";
import InfoBanner from "./components/InfoBanner";
import Landing from "./components/Landing";
import SecretSuccess from "./components/SecretSuccess";
import Part1Formation from "./components/Part1Formation";
import Part2Elearning from "./components/Part2Elearning";
import Footer from "../../components/Footer";
import ValeursFondatrices from "./components/ValeursFondatrices";
import Testimonies from "./components/Testimonies";
import CallToAction from "./components/CallToAction";
import SecretSuccessMobile from "./components/SecretSuccessMobile";
import ValeursFondatricesMobile from "./components/ValeursFondatricesMobile";

export default function HomePage() {

    const [width, setWidth] = useState(800);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
        return () => window.removeEventListener("resize", Update);
    }, []);

    function Update() {
        setWidth(window.innerWidth);
    }

    return(
        <div className={'page'}>
            {width > 945 &&
                <InfoBanner/>
            }

            <Landing/>

            <div id={'elementTarget'}>
                {width < 945 ?
                    <SecretSuccessMobile/>
                    :
                    <SecretSuccess/>
                }
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Part1Formation width={width}/>
                <Part2Elearning/>
            </div>

            {width < 1022 ?
                <ValeursFondatricesMobile/>
                :
                <ValeursFondatrices/>
            }

            <Testimonies/>
            <div style={{zIndex: 2}}>
                <CallToAction/>
            </div>
            {/*<FrequentlyAskedQuestions/>*/}
            <Footer/>
        </div>
    )
}
