import React from "react";
import elearning from '../../../assets/elearning.png'

export default function Part2Elearning() {

    return(
        <div className={'part2Elearning'} style={{overflow: 'hidden'}}>
            <div className={'containerPart'} style={{zIndex: 2}}>

                <div className={'textPart'}>

                    <p className={'step'}>FORMATION EN LIGNE</p>
                    <p className={'titleStep'}>Une formation continue sur 52 semaines</p>
                    <p className={'corpsStep'}>Notre parcours d’apprentissage immersif, permet un développement interne profond et une croissance professionnelle durable. Au travers d'une vidéo chaque semaine découvrez de nouvelles perspectives et outils pour nourrir votre potentiel et forger votre chemin vers l’excellence.</p>

                    <a href="https://courses.kbi-academie.fr/courses/l-athlete-interieur" target="_blank" rel="noopener noreferrer" className="buttonMore">
                        <div className={'button'}>
                            Accéder à la formation
                        </div>
                    </a>

                </div>
                <div className={'intervenantPart'}>
                    <img src={elearning} alt="" className={'intervenant'}/>
                </div>
            </div>
        </div>
    )
}
