import React from "react";
import logoKbi from '../assets/logoKbi.png'

export default function Footer({width}) {

    return(
        <div className={'footer'}>
            <div className={'containerFooter'}>
                <div style={{marginLeft: 30}}>
                    <img src={logoKbi} alt="" className={'logoKbi'}/>
                    <p className={'littleTitleGrey'} style={{marginBottom: 5, color: '#FFF'}}>KBI Europe</p>

                    {/*
                    <div className={'containerLogoSocial'}>
                        <img src={logoFacebook} alt=""/>
                        <img src={logoInsta} alt=""/>
                        <img src={logoX} alt=""/>
                        <img src={logoLinkedin} alt=""/>
                        <img src={logoYoutube} alt=""/>
                        <img src={logoTiktok} alt=""/>
                    </div>
                    */}

                    <p className={'littleTitleGrey'} style={{marginTop: 80}}>@2023 KBI Europe</p>
                </div>
            </div>
        </div>
    )
}
