import React from "react";
import colorGradient from '../../../assets/colorGradient.png'
import secret1 from '../../../assets/secret1.png'
import secret2 from '../../../assets/secret2.png'
import secret3 from '../../../assets/secret3.png'

export default function SecretSuccess() {

    function TextComponent({title, subtitle, titleEnd, top}) {

        return(
            <div className={'textComponent'} style={{marginTop: top ? -30 : null}}>
                <p className={'titleTextComponent'}>{title} <span className={'titleEnd'}>{titleEnd}</span></p>
                <p className={'subtitleTextComponent'}>{subtitle}</p>
            </div>
        )
    }

    return(
        <div className={'secretSuccess'}>

            <p className={'subtitle'}>UNE MÉTHODE, UNE PHILOSOPHIE...</p>
            <p className={'title'}>Le secret de l'Engagement</p>

            <div className={'threeSteps'}>
                <div className={'leftPart'}>
                    <TextComponent title={'Un constat : Le monde du'} titleEnd={'Travail en crise'} subtitle={'Première étape du développement personnel, l’acceptation des défauts du système actuel et la reconnaissance de ses défauts.'} top={true}/>
                    <img src={secret2} alt="" className={'secret2'}/>

                    <TextComponent title={'Un objectif : Donner du'} titleEnd={'Sens à votre équipe'} subtitle={'La philosophie KBI ? Montrer à vos collaborateurs qu’ils participent à la réalisation d’un objectif qui les dépasse. Leur abnégation à l’objectif commun est valorisé par l’ensemble de l’équipe.'}/>
                </div>
                <div className={'center'}>
                    <div className={'square'}>
                        01.
                    </div>
                    <div className={'blackBar'}/>
                    <div className={'square'}>
                        02.
                    </div>
                    <div className={'blackBar'}/>
                    <div className={'square'}>
                        03.
                    </div>
                </div>
                <div className={'rightPart'}>
                    <img src={secret1} alt="" className={'secret1'}/>
                    <TextComponent title={'Une méthode : Le sport'} titleEnd={'Comme modèle'} subtitle={'KBI s\'inspire des méthodes employées par les plus grands athlètes pour permettre à votre équipe de s’épanouir dans l’effort et de repousser ses limites.'}/>
                    <img src={secret3} alt="" className={'secret3'}/>

                </div>
            </div>
        </div>
    )
}
