import React from "react";

export default function InfoBanner() {

    return(
        <div className={'infoBanner'}>
            <p className={'text'}>
                Vous êtres sur le point de découvrir la méthode KBI. Vos employés vont enfin pouvoir retrouver du sens dans leur travail.            </p>
        </div>
    )
}
