import React from "react";

export default function Testimonies() {

    return(
        <div className={'testimonies'}>
            <p className={'title'}>Les professionnels <span className={'degrade'}>témoignent</span></p>
            <iframe
                className={'youtube'}
                width="1000"
                height="560"
                src="https://www.youtube.com/embed/RwJeTl9pqmw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    )
}
