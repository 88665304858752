import React from "react";
import colorGradient from "../../../assets/colorGradient.png";

export default function CallToAction() {

    return(
        <div className={'cta'}>

            <div className={'widgetCta'}>

                <p className={'startNow'}>COMMENCEZ DÈS MAINTENANT AVEC VOTRE ÉQUIPE</p>
                <p className={'subtitle'}>Des milliers de professionels formés dans le programme</p>

                <p className={'title'}>
                    Vers l’entreprise <br/>
                    de <span className={'degrade'} style={{fontWeight: 600}}>demain</span>
                </p>

                <a href="mailto:jbbevillard@kbi-europe.fr?" className="btn">
                    <div className={'buttonCta'}>
                        CONTACTER NOTRE ÉQUIPE <br/>
                        <span className={'mail'}>jbbevillard@kbi-europe.fr</span>
                    </div>
                </a>



            </div>

        </div>
    )
}
