import React from "react";
import colorGradient from '../../../assets/colorGradient.png'
import secret1 from '../../../assets/secret1.png'
import secret2 from '../../../assets/secret2.png'
import secret3 from '../../../assets/secret3.png'

export default function SecretSuccessMobile() {

    function TextComponent({title, subtitle, titleEnd, top}) {

        return(
            <div className={'textComponent'} style={{marginTop: top ? -30 : null}}>
                <p className={'titleTextComponent'}>{title} <span className={'titleEnd'}>{titleEnd}</span></p>
                <p className={'subtitleTextComponent'}>{subtitle}</p>
            </div>
        )
    }

    return(
        <div className={'secretSuccessMobile'}>

            <p className={'subtitle'}>UNE MÉTHODE, UNE PHILOSOPHIE...</p>
            <p className={'title'}>Le secret de l'Engagement</p>

            <div className={'threeSteps'}>

                <div className={'leftPart'}>
                    <div style={{display: 'flex', alignItems: "flex-end"}}>
                        <div className={'square'} style={{marginBottom: 30}}>
                            01.
                        </div>
                        <img src={secret1} alt="" className={'secret1'}/>
                    </div>
                    <TextComponent title={'Un constat : Le monde du'} titleEnd={'Travail en crise'} subtitle={'Première étape du développement personnel, l’acceptation des défauts du système actuel et la reconnaissance de ses vices.'} top={true}/>

                    <div style={{display: 'flex', alignItems: "flex-end"}}>
                        <div className={'square'} style={{marginBottom: 7}}>
                            02.
                        </div>
                        <img src={secret2} alt="" className={'secret2'}/>
                    </div>
                    <TextComponent title={'Une méthode : Le sport'} titleEnd={'Comme modèle'} subtitle={'KBI s\'inspire des méthodes employées par les plus grands athlètes pour permettre à votre équipe de s’épanouir dans l’effort et de repousser ses limites.'}/>

                    <div style={{display: 'flex', alignItems: "flex-end"}}>
                        <div className={'square'} style={{marginBottom: 8}}>
                            03.
                        </div>
                        <img src={secret3} alt="" className={'secret3'}/>
                    </div>
                    <TextComponent title={'Un objectif : Donner du'} titleEnd={'Sens à votre équipe'} subtitle={'La philosophie KBI ? Montrer à vos collaborateurs qu’ils participent à la réalisation d’un objectif qui les dépasse. Leur abnégation à l’objectif commun est valorisé par l’ensemble de l’équipe.'}/>
                </div>
                <div className={'rightPart'}>


                </div>
            </div>
        </div>
    )
}
