import React from "react";
import valeur1 from '../../../assets/valeur1.png'
import valeur2 from '../../../assets/valeur2.png'
import valeur3 from '../../../assets/valeur3.png'
import karate from '../../../assets/karate.png'
export default function ValeursFondatrices() {

    function Valeur({name, degrade, img, corps}) {
        return(
            <div className={'valeur'}>
                <div className={'textPart'}>
                    <p className={'title'}>{name} <span className={'degrade'}>{degrade}</span></p>
                    <p className={'corps'}>{corps}</p>
                </div>
                <div className={'picturePart'}>
                    <img src={img} alt=""/>
                </div>
            </div>
        )
    }

    return(
        <div className={'valeursFondatrices'}>

            <p className={'firstTitle'}>KBI EN QUELQUES MOTS…</p>
            <p className={'mainTitle'}>Nos 3 Valeurs fondatrices</p>

            <Valeur
                img={valeur1}
                name={'Donnez du'}
                degrade={'sens à vos équipes'}
                corps={
                    <>
                        KBI donne un sens au travail de vos employés en cultivant une culture d’excellence.
                        <strong> Nos méthodes alignent les valeurs individuelles avec les objectifs de l’entreprise, rendant chaque rôle significatif. </strong>
                        En promouvant l’auto-amélioration et le développement des compétences, KBI rend le travail plus gratifiant, renforçant ainsi l’engagement et la motivation.
                    </>
                }
            />
            <Valeur
                img={valeur2}
                name={'Excellez comme un'}
                degrade={'athlète'}
                corps={
                    <>
                        Suite à l’intervention de KBI, votre équipe se métamorphosera en une unité de haute performance, similaire à des athlètes d’élite.
                        <strong> Chaque membre sera armé d’outils, prêt à s’entraîner quotidiennement pour améliorer ses performances. </strong>
                        Vos dirigeants, adopteront de nouvelles perspectives pour cultiver une culture d’excellence, comme des entraîneurs de haut niveau. Ensemble, ils développerons une excellence intérieur avec un engagement quotidien pour atteindre leur potentiel maximal.
                    </>
                }
            />

            <Valeur
                img={karate}
                name={'Un combat'}
                degrade={'quotidien'}
                corps={
                    <>
                        Chez KBI, nous croyons que l’excellence est le fruit d’un combat quotidien, pas un objectif atteint en une fois. Comme un athlète s’entraînant chaque jour, nous devons nous exercer sans cesse pour devenir meilleurs. Chaque jour est une chance de grandir et de nous surpasser.
                        <strong> Accepter ce défi quotidien et s’engager à l’amélioration continue est le véritable chemin vers l’excellence, </strong>
                        et KBI vous fournit les outils nécessaires pour ce voyage.
                    </>
                }
            />

        </div>
    )
}
