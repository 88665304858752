import React from "react";
import valeur1 from '../../../assets/valeur1.png'
import valeur2 from '../../../assets/valeur2.png'
import valeur3 from '../../../assets/valeur3.png'
import karate from '../../../assets/karate.png'
export default function ValeursFondatricesMobile() {

    function Valeur({name, degrade, img, color}) {
        return(
            <div className={'valeur'} style={{backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), ${color}), url(${img})`}}>
                <div className={'textPart'}>
                    <p className={'title'}>{name} <span className={'degrade'}>{degrade}</span></p>
                </div>

            </div>
        )
    }

    return(
        <div className={'valeursFondatricesMobile'}>

            <p className={'firstTitle'}>KBI EN QUELQUES MOTS…</p>
            <p className={'mainTitle'}>Nos 3 Valeurs fondatrices</p>

            <div className={'containerValeurs'} style={{marginTop: -35}}>
                <div className={'comble'}/>
                <Valeur
                    img={valeur1}
                    name={'Donnez du'}
                    degrade={'sens à vos équipes'}
                    color={'rgb(61, 136, 228)'}

                />
                <Valeur
                    img={valeur2}
                    name={'Excellez comme un'}
                    degrade={'athlète'}
                    color={'rgb(83,228,61)'}

                />

                <Valeur
                    img={karate}
                    name={'Un combat'}
                    degrade={'quotidien'}
                    color={'rgb(228,131,61)'}

                />
                <div className={'comble'}>d</div>

            </div>


        </div>
    )
}
